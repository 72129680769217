import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
const cookies = new Cookies();

export default class AddYtdRun extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      description: this.state.description,
      paysched_id: this.state.paysched_id,
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
    };
    this.setState({ disableSubmit: true });
    axios.post("api/add_ytdrun", data).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showDeleteModal: false, disableSubmit: false });
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-sm btn-outline-primary"
          title="Add Ytd Adjusment Run"
          onClick={this.handleshowDeleteModal}
        >
          <Add /> Add Ytd Adjusment Run
        </button>
        <Modal
          size="md"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header closeButton>
            <h4>
              Add Ytd Adjusment Run:
              <br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Decription</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Period ID</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ paysched_id: e.target.value });
                    }}
                  >
                    <option value="">Select Pay Period ID</option>
                    {this.props.payschedDD.map((paysched) => (
                      <option key={paysched.id} value={paysched.id}>
                        {paysched.code +
                          " (" +
                          dateFormatter(paysched.start_date) +
                          " - " +
                          dateFormatter(paysched.start_date) +
                          ")"}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
