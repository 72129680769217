import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FindInPage, Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
import AddYtdRun from "./AddYtdRun";
import EditYtdRun from "./EditYtdRun";
import YtdDraftRun from "./YtdAdjDraftRun";
import YtdAdjApprove from "./YtdAdjApprove";
import YtdAdjCancelApprove from "./YtdAdjCancelApprove";
import YtdAdjPost from "./YtdAdjPost";
const statuses = ["DRAFT", "FOR POSTING", "POSTED"];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function YtdAdjustmentRun(props) {
  const permissions = cookies.get("permissions")["ytd"];
  const [data, setData] = useState([]);
  const [schedData, setSchedData] = useState([]);
  const [checkRunnable, setCheckRunnable] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(12);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [payschedDD, setPayschedDD] = useState([]);
  const [spHeader, setSpHeader] = useState([]);
  const [search, setSearch] = useState(
    props.location.search
      ? decodeURI(props.location.search.split("=").pop())
      : ""
  );

  useEffect(() => {
    const fetchData = async () => {
      let company_id = 0;
      if (cookies.get("transCompany")) company_id = cookies.get("transCompany");
      if (company_id == 0) {
        cookies.set("load", "1");
        alert("Please Select Module Company");
        window.location.replace("/#/app");
      } else {
        let apiLink = "api/ytd_run/" + cookies.get("transCompany");
        const res = await axios.get(apiLink);

        setData(res.data.YtdAdjHistory);
        setCheckRunnable(res.data.checkRunnable);
        const filterer = searcher(res.data.YtdAdjHistory, search);
        setFilteredData(filterer);
        let datas = [];
        if (res.data.payschedDD1 != null) {
          res.data.payschedDD1.map((paysched) => {
            datas.push(paysched);
          });
        }

        if (res.data.payschedDD2 != null) datas.push(res.data.payschedDD2);

        setPayschedDD(datas);

        setSpHeader(res.data.ytdHeader);
        if (res.data.checkDraft) {
          setSchedData(res.data.checkDraft);
        } else {
          setSchedData([]);
        }
      }
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const noTransac = () => {
    alert("No Open YTD Adjustment Records Found");
  };
  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["code"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["ps_code"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["description"]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <h3 className="mb-10">YTD Adjustment Run</h3>
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="card">
                            <div className="card-header card-header-action">
                              <h5>Set-up YTD Adjustment Processing</h5>
                              <div className="d-flex align-items-center card-action-wrap-tools">
                                {schedData.id ? (
                                  permissions > 2 ? (
                                    <EditYtdRun
                                      schedData={spHeader}
                                      parentCallback={handleAddCallback}
                                      payschedDD={payschedDD}
                                    />
                                  ) : (
                                    ""
                                  )
                                ) : checkRunnable > 0 ? (
                                  permissions > 1 ? (
                                    <AddYtdRun
                                      payschedDD={payschedDD}
                                      parentCallback={handleAddCallback}
                                    />
                                  ) : (
                                    ""
                                  )
                                ) : permissions > 1 ? (
                                  <button
                                    className="btn btn-sm btn-outline-primary"
                                    title="Draft Run Special Payroll"
                                    onClick={noTransac}
                                  >
                                    <Add /> Add YTD Adjustment Run
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="card-body">
                              <div className="row mb-20">
                                <div className="col-xl-2 col-sm-10 ">
                                  <strong>
                                    YTD Adjustment Run Description
                                  </strong>
                                </div>
                                <div className="col-xl-4 col-sm-10">
                                  :&nbsp;&nbsp;&nbsp;
                                  {spHeader.description
                                    ? spHeader.description
                                    : "N/A"}
                                </div>

                                <div className="col-xl-2 col-sm-10">
                                  <strong>Status</strong>
                                </div>
                                <div className="col-xl-4 col-sm-10">
                                  :&nbsp;&nbsp;&nbsp;
                                  {schedData.status == 3 ? (
                                    <strong className="text-success">
                                      POSTED
                                    </strong>
                                  ) : schedData.status == 2 ? (
                                    <strong className="text-info">
                                      FOR POSTING
                                    </strong>
                                  ) : schedData.status == 1 ? (
                                    <strong className="text-warning">
                                      DRAFT
                                    </strong>
                                  ) : (
                                    <strong className="text-danger">
                                      OPEN
                                    </strong>
                                  )}
                                </div>

                                <div className="col-xl-2 col-sm-10 ">
                                  <strong>Pay Period ID</strong>
                                </div>
                                <div className="col-xl-4 col-sm-10">
                                  :&nbsp;&nbsp;&nbsp;
                                  {spHeader.sched_code
                                    ? spHeader.sched_code +
                                      " (" +
                                      dateFormatter(spHeader.start_date) +
                                      " - " +
                                      dateFormatter(spHeader.start_date) +
                                      ")"
                                    : "N/A"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {permissions > 2 ? (
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card">
                              <div className="card-header card-header-action">
                                <h5>Processing Status</h5>
                                <div className="d-flex align-items-center card-action-wrap-tools"></div>
                              </div>

                              <div className="card-body">
                                <div className="row mb-10">
                                  <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        {schedData.status == 1 ? (
                                          <YtdDraftRun
                                            rerun={1}
                                            schedData={spHeader}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : schedData.status == 0 ? (
                                          <YtdDraftRun
                                            schedData={spHeader}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : (
                                          <button
                                            className="btn btn-sm btn-secondary"
                                            disabled="disabled"
                                          >
                                            Run Draft
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-10">
                                        <b className="font-weight-bold text">
                                          Draft Run Date:{" "}
                                          {schedData.draft_date ? (
                                            <strong className="text-info">
                                              {dateFormatter(
                                                schedData.draft_date,
                                                "MMM dd, yyyy hh:mm bb"
                                              )}
                                            </strong>
                                          ) : (
                                            <strong className="text-danger">
                                              PENDING
                                            </strong>
                                          )}
                                        </b>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        {schedData.id ? (
                                          schedData.status < 2 ? (
                                            <YtdAdjApprove
                                              schedData={schedData}
                                              parentCallback={handleAddCallback}
                                            />
                                          ) : (
                                            <YtdAdjCancelApprove
                                              schedData={schedData}
                                              parentCallback={handleAddCallback}
                                            />
                                          )
                                        ) : (
                                          <button
                                            className="btn btn-sm btn-info"
                                            disabled="disabled"
                                          >
                                            Reviewed For Posting
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-10">
                                        <b className="font-weight-bold text">
                                          Review Date:{" "}
                                          {schedData.review_date ? (
                                            <strong className="text-info">
                                              {dateFormatter(
                                                schedData.review_date,
                                                "MMM dd, yyyy hh:mm bb"
                                              )}
                                            </strong>
                                          ) : (
                                            <strong className="text-danger">
                                              PENDING
                                            </strong>
                                          )}
                                        </b>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        {schedData.id ? (
                                          <YtdAdjPost
                                            schedData={schedData}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : (
                                          <button
                                            className="btn btn-sm btn-success"
                                            disabled="disabled"
                                          >
                                            Post YTD Adjustment
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-10">
                                        <b className="font-weight-bold text">
                                          Post Date:{" "}
                                          {schedData.post_date ? (
                                            <strong className="text-info">
                                              {dateFormatter(
                                                schedData.post_date,
                                                "MMM dd, yyyy hh:mm bb"
                                              )}
                                            </strong>
                                          ) : (
                                            <strong className="text-danger">
                                              PENDING
                                            </strong>
                                          )}
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row mb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="card">
                            <div className="card-header card-header-action">
                              <h5 className="mb-10">History</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <label>
                                    <select
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                      onChange={handleRecordPerPage}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </label>{" "}
                                  items
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="search"
                                    className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                                    placeholder="Search"
                                    defaultValue={
                                      props.location.search
                                        ? decodeURI(
                                            props.location.search
                                              .split("=")
                                              .pop()
                                          )
                                        : ""
                                    }
                                    onChange={handleSearch}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <div className="table-responsive mb-10">
                                    <table className="table table-sm table-bordered table-striped mb-0">
                                      <thead className="thead-secondary">
                                        <tr className="text-center text-nowrap">
                                          <th>Run Code</th>
                                          <th>Description</th>
                                          <th>Pay Period ID</th>
                                          <th>Status</th>
                                          <th>Head Count</th>
                                          <th>Basic</th>
                                          <th>De Minimis</th>
                                          <th>O.Earnings</th>
                                          <th>Gross Pay</th>
                                          <th>Statutories</th>
                                          <th>O.Deductions</th>
                                          <th>WH Tax</th>
                                          <th>Net Pay</th>
                                          <th>Date Posted</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {filteredData.length > 0 ? (
                                          currentData.map((cutoff) => (
                                            <tr key={cutoff.id}>
                                              <td>
                                                <Link
                                                  to={{
                                                    pathname: `/ytd_run/${cutoff.id}/main`,
                                                    query: `/ytd_run`,
                                                    search: "?search=" + search,
                                                  }}
                                                  className="text-primary text-nowrap"
                                                  title={"View per employee"}
                                                >
                                                  {cutoff.code}
                                                </Link>
                                              </td>
                                              <td className="text-nowrap">
                                                {cutoff.description}
                                              </td>
                                              <td className="text-center">
                                                {cutoff.ps_code}
                                              </td>
                                              <td className="text-center">
                                                {cutoff.status == 3 ? (
                                                  <strong className="text-success">
                                                    POSTED
                                                  </strong>
                                                ) : cutoff.status == 2 ? (
                                                  <strong className="text-info">
                                                    FOR POSTING
                                                  </strong>
                                                ) : cutoff.status == 1 ? (
                                                  <strong className="text-warning">
                                                    DRAFT
                                                  </strong>
                                                ) : (
                                                  <strong className="text-danger">
                                                    OPEN
                                                  </strong>
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.headcount
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.basic_pay
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.deminimis
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.earnings
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.gross
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.statutories
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.deductions
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.wh_tax
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.net
                                                  .toString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>

                                              <td className="text-center text-nowrap">
                                                {cutoff.post_date ? (
                                                  dateFormatter(
                                                    cutoff.post_date,
                                                    "MMM dd, yyyy hh:mm bb"
                                                  )
                                                ) : (
                                                  <strong className="text-danger">
                                                    PENDING
                                                  </strong>
                                                )}
                                              </td>
                                              <td>
                                                <div className="btn-group">
                                                  <Link
                                                    to={{
                                                      pathname: `/ytd_run/${cutoff.id}/main`,
                                                      query: `/ytd_run`,
                                                      search:
                                                        "?search=" + search,
                                                    }}
                                                    className="btn btn-sm btn-outline-primary"
                                                    title={"View per employee"}
                                                  >
                                                    <FindInPage />
                                                  </Link>
                                                </div>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="15"
                                              className="text-center"
                                            >
                                              No Data to show
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="drew-pagination">
                                    {filteredData.length > 0
                                      ? "Showing " +
                                        (startIndex + 1) +
                                        " to " +
                                        (endIndex > filteredData.length
                                          ? filteredData.length
                                          : endIndex) +
                                        " of " +
                                        filteredData.length
                                      : ""}
                                    <ul class="pagination ml-2">
                                      {pages.map((number) => (
                                        <li
                                          key={number}
                                          id={number}
                                          className={
                                            currentPage == number
                                              ? "paginate_button page-item active"
                                              : "paginate_button page-item"
                                          }
                                          onClick={() => setCurrentPage(number)}
                                        >
                                          <a className="page-link">{number}</a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : YTD Adjustments
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
